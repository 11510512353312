// Colors
$bg-color: var(--rte-bg, #ffffff);
$fg-color: var(--rte-fg, #1f2328);
$border-color: var(--rte-border);
$primary-color: var(--rte-primary);
$primary-fg-color: var(--rte-primary-fg);
$secondary-color: var(--rte-secondary);
$secondary-fg-color: var(--rte-secondary-fg);
$accent-color: var(--rte-accent);
$accent-fg-color: var(--rte-accent-fg);
$muted-color: var(--rte-muted);
$muted-fg-color: var(--rte-muted-fg);
$tooltip-color: var(--rte-tooltip);
$tooltip-fg-color: var(--rte-tooltip-fg);
$bubble: var(--rte-bubble, $bg-color);
$overlay: var(--rte-overlay);

// Font
$text-size-xs: 0.75rem;
$text-size-sm: 0.875rem;
$text-size-base: 1rem;
$text-size-lg: 1.125rem;
$text-size-xl: 1.25rem;
$text-size-2xl: 1.5rem;

$text-lineHeight-xs: 1rem;
$text-lineHeight-sm: 1.25rem;
$text-lineHeight-base: 1.5rem;
$text-lineHeight-lg: 1.75rem;
$text-lineHeight-xl: 1.75rem;
$text-lineHeight-2xl: 2rem;

$font-sizes: (
  "xs": (
    $text-size-xs,
    $text-lineHeight-xs,
  ),
  "sm": (
    $text-size-sm,
    $text-lineHeight-sm,
  ),
  "base": (
    $text-size-base,
    $text-lineHeight-base,
  ),
  "lg": (
    $text-size-lg,
    $text-lineHeight-lg,
  ),
  "xl": (
    $text-size-xl,
    $text-lineHeight-xl,
  ),
  "2xl": (
    $text-size-2xl,
    $text-lineHeight-2xl,
  ),
);

// Radius
$radius: var(--rte-radius, 0.5rem);
$radius-lg: calc($radius * 1.25);
$radius-sm: calc($radius * 0.75);

// Shadow
$shadow-1: var(
  --rte-shadow-1,
  0px 0px 0px 1px #d1d9e080,
  0px 6px 12px -3px #25292e0a,
  0px 6px 18px 0px #25292e1f
);
$shadow-2: var(
  --rte-shadow-2,
  0px 0px 0px 1px #d1d9e0,
  0px 8px 16px -4px #25292e14,
  0px 4px 32px -4px #25292e14,
  0px 24px 48px -12px #25292e14,
  0px 48px 96px -24px #25292e14
);
$shadow-3: var(--rte-shadow-3, 0px 0px 0px 1px #d1d9e0, 0px 40px 80px 0px #25292e3d);

// Editor
$editor-min-height: var(--rte-editor-min-height);
$editor-max-height: var(--rte-editor-max-height);
$editor-font-size: var(--rte-editor-font-size, 1rem);
$editor-link: var(--rte-editor-link, $primary-color);
$editor-line-height: var(--rte-editor-line-height, 1.75);
$editor-selection: var(--rte-editor-selection, tint-color($primary-color, 0.2));
$editor-code-bg: var(--rte-editor-code-bg, #f6f8fa);
$editor-scrollbar: var(--rte-editor-scrollbar, #00000040);
$editor-font: var(
  --rte-editor-font,
  -apple-system,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Helvetica,
  Arial,
  sans-serif,
  Apple Color Emoji,
  Segoe UI Emoji
);
$editor-code-font: var(
  --rte-editor-code-font,
  ui-monospace,
  SFMono-Regular,
  SF Mono,
  Menlo,
  Consolas,
  Liberation Mono,
  monospace
);
