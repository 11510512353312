.ProseMirror-selectednode {
  @at-root {
    &:has(iframe),
    img#{&} {
      box-shadow:
        0 0 0 3px $bg-color,
        0 0 0 5px $primary-color;
    }
  }
}

.ProseMirror {
  flex: 1;
  outline: none;
  width: 100%;
  white-space: pre-wrap;
  position: relative;
  padding-block: 1.75rem;

  // Editable content styles
  &[contenteditable="true"] {
    .ProseMirror-selectednode:has(iframe),
    img.ProseMirror-selectednode,
    img:has(+ figcaption.ProseMirror-selectednode) {
      box-shadow:
        0 0 0 3px $bg-color,
        0 0 0 5px $primary-color;
    }
  }

  &.resize-cursor {
    cursor: col-resize;
  }

  // Element spacing
  & > * {
    margin-top: 1.25rem;

    &:first-child {
      margin-top: 0;
    }
  }

  // Indentation levels
  @for $i from 1 through 4 {
    [data-indent="#{$i}"] {
      text-indent: 1em * $i;
    }
  }

  // Placeholder styles
  :where(& > p.is-editor-empty:first-child, figcaption.is-empty)::before {
    content: attr(data-placeholder);
    float: left;
    color: $muted-fg-color;
    pointer-events: none;
    width: 100%;
    height: 0;
  }

  // Selection styles
  ::selection,
  .selection {
    background-color: $editor-selection;
  }

  .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    inset: 0;
    background-color: $editor-selection;
    pointer-events: none;
  }

  // Heading styles
  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
    margin-top: 1.25rem;
  }

  h1 {
    font-size: 1.75em;
    margin-top: 1.75rem;
  }

  h2 {
    font-size: 1.625em;
    margin-top: 1.75rem;
  }

  h3 {
    font-size: 1.25em;
    margin-top: 1.5rem;
  }

  h4 {
    font-size: 1em;
  }

  // Paragraph styles
  p {
    font-size: 1em;
    line-height: 1.75;
  }

  // Link styles
  a {
    color: $editor-link;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }

    &:not([href]) {
      color: inherit;
      text-decoration: none;
      font-weight: normal;
    }
  }

  // List styles
  ul,
  ol {
    padding-inline: 2rem;

    li {
      margin-block: 0.375rem;
      padding-inline-start: 0.375rem;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  // Blockquote styles
  blockquote {
    padding: 0.375rem 1.25rem;
    font-style: italic;
    border-left: 0.25rem solid $border-color;
    //  font-weight: 600;
  }

  // Media styles
  img,
  iframe,
  video {
    display: block;
    max-width: 100%;
    height: auto;
    margin-inline: auto;
    cursor: default;
  }

  iframe {
    width: 100%;
    margin: 0 auto;
    pointer-events: none;
    aspect-ratio: 16 / 9;
    position: relative;
  }

  // Figure and figcaption styles
  figure {
    cursor: default;
    user-select: none;
  }

  figcaption {
    font-size: 90%;
    color: $muted-fg-color;
    text-align: center;
    line-height: 1.4;
    cursor: text;
    padding: 0.5rem 1.5rem 0.25rem;
    margin-bottom: -0.25rem;
  }

  // Code and preformatted text styles
  code,
  kbd,
  samp,
  pre {
    font-family: $editor-code-font;
    font-feature-settings: normal;
    font-variation-settings: normal;
  }

  code {
    background: $editor-code-bg;
    border: 1px solid $border-color;
    border-radius: $radius-sm;
    padding: 0.15rem 0.3rem;
    font-size: 90%;
  }

  pre {
    white-space: pre !important;
    tab-size: 2;
    line-height: 1.55;
    padding: 0.75rem 1.25rem;
    background: $editor-code-bg;
    border: 1px solid $border-color;
    color: $fg-color;
    @include custom-scrollbar;

    code {
      background: transparent;
      padding: 0;
      border: none;
    }
  }

  .tableWrapper {
    @include custom-scrollbar;
  }

  table {
    border: 1px solid $border-color;
    font-size: 90%;
    overflow: hidden;
    border-collapse: collapse;
    table-layout: fixed;
    margin-inline: auto;

    th,
    td {
      border: 1px solid $border-color;
      padding: 0.5rem 0.75rem;
      position: relative;
      vertical-align: top;
      text-align: left;
    }

    th {
      background-color: $muted-color;
    }

    .column-resize-handle {
      background-color: $primary-color;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -1px;
      top: 0;
      width: 3.5px;
      z-index: 10;
    }
  }
}
