.media-library {
   width: 90vw;
   height: 95vh;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   background-color: var(--rte-bg);

   &__header,
   &__footer {
      display: flex;
      padding: 0.75rem 1.5rem;
      align-items: center;
      border: 1px solid var(--rte-border);
   }

   &__header {
      justify-content: space-between;
      border-top: none;

      h2 {
         font-size: 1.25rem;
         font-weight: 600;
      }
   }

   &__footer {
      justify-content: flex-end;
      gap: 1rem;
      border-bottom: none;
   }

   &__content {
      display: flex;
      height: 100%;
      flex: 1;
      overflow: hidden;
   }

   &__spinner {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 6px solid var(--rte-primary);
      border-top-color: transparent;
      margin: auto;
      animation: spin 1s linear infinite;
   }
}

.media-gallery {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
   gap: 1.25rem;
   padding: 1.5rem;
   overflow: auto;
   scrollbar-width: thin;
   flex: 1;
}

.media-item {
   display: flex;
   position: relative;
   flex-direction: column;
   border: 1px solid var(--rte-border);
   border-radius: 6px;
   cursor: pointer;

   &__info {
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
      border-top: 1px solid var(--rte-border);
   }

   &__name {
      font-size: 15px;
      font-weight: bold;
   }

   &__details {
      font-size: 13px;
   }

   &__checkbox {
      visibility: hidden;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 50%;
      border: 1px solid var(--rte-border);
      background-color: var(--rte-bg);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      svg {
         width: 1.125rem;
         height: 1.125rem;
      }
   }

   &__image-wrapper {
      width: 100%;
      height: 10rem;
      display: flex;
      justify-content: center;
      background: repeating-conic-gradient(#e5e5ed 0% 25%, transparent 0% 50%) 50% / 20px 20px;

      img {
         max-height: 100%;
      }
   }

   &:hover &__checkbox {
      visibility: visible;
   }

   &--selected {
      box-shadow: 0 0 0 2px var(--rte-primary);

      .media-item__checkbox {
         background-color: var(--rte-primary);
         visibility: visible;
      }
   }

   &--uploading {
      opacity: 20%;
      pointer-events: none;
   }
}

html.dark .media-item__image-wrapper {
   background: repeating-conic-gradient(#292936 0% 25%, transparent 0% 50%) 50% / 20px 20px;
}

@keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
}

@media (min-width: 768px) {
   .media-library {
      width: 70vw;
      height: 90vh;
   }
}

